var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "scrollBox",
      staticClass: "fun_task_dialog",
      attrs: { id: "fun_task_dialog" },
      on: {
        dragenter: _vm.handleDragEnter,
        dragover: _vm.handleDragOver,
        dragleave: _vm.handleDragLeave,
        drop: _vm.handleDrop,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isGettingMsg && !_vm.nomore,
              expression: "isGettingMsg && !nomore",
            },
          ],
          staticClass: "loading_group",
        },
        [
          _c("div", { staticClass: "icon" }),
          _c("div", { staticClass: "text" }, [_vm._v("加载中")]),
        ]
      ),
      _vm.nomore
        ? _c("div", { staticClass: "nomore_group" }, [
            _c("div", { staticClass: "nomore" }, [_vm._v("没有新的消息了")]),
          ])
        : _vm._e(),
      _vm._l(_vm.message_list, function (item, index) {
        return [
          item.logType !== 1 &&
          item.logType !== 5 &&
          !_vm.is_system &&
          !item.pseudo_delete
            ? _c(
                "div",
                {
                  key: index + "22",
                  ref: "dialog_unit",
                  refInFor: true,
                  staticClass: "dialog_unit",
                },
                [
                  _c("msg-unit", {
                    attrs: {
                      type:
                        Number(item.fromUserId) === Number(_vm.userId)
                          ? "send"
                          : "receive",
                      msg_data: item,
                      prev:
                        index !== 0 ? _vm.message_list[index - 1] : undefined,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          item.logType === 1 || item.logType === 5
            ? _c(
                "div",
                { key: index, ref: "dialog_unit", refInFor: true },
                [_c("welcome-unit", { attrs: { item: item } })],
                1
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }